<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Créer une ligne de confiance pour un token</h2>
		</div>
		<h2>
			Principe de base</h2>
		<p>
			Une personne désireuse de recevoit un token qu'elle ne posséde pas encore doit créer une ligne de confiance pour ce token afin d'autoriser la réception de celui-ci sur son compte.




			<br /> <br />
			Pour cela, il suffit de créer un ordre d'achat d'un montant de 0.

		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Juliette veut recevoir des Berlcoins de la part d'un parent. Ella va créer une ligne de confiance en réalisant un achat avec une quantité de 0.

			<br />
		</p>

		<h3 class="mt-12">
			Situation initiale</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/trust-1.png" />
				<br /> <br /> <b> Le compte de Juliette</b><br />
			</div>

		</div>


		<h3 class="mt-12">
			Ordre d'achat du Berlcoin</h3>


		<p>Dans la section <b>Achat</b>.</p>


		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/trust-2.png" />
				<br /> <br /> <b> Juliette sélectionne le menu Achat et arrive sur la liste des tokens disponibles s'affiche </b><br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/trust-3.png" /><br />
				<br /><b>Juliette sélectionne le token Berlcoin, le menu des ordres s'affiche, elle clique sur ORDRE D'ACHAT </b>
			</div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/trust-4.png" /><br />
				<br /><b>Juliette indique 0 en quantité, conserve le prix du jour et valide. </b>
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/trust-5.png" /><br />
				<br /><b> Son compte affiche désormais le Berlcoin comme token acceptable </b>
			</div>


		</div>




		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Juliette a payé 0.00001 XLM (ici à 0.21 euro pour 1 XLM ) pour l'ordre d'achat.
				<br /> <br /> Au final moins de 1 centime d'euro.
			</p>
		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
